<template>
  <LayoutMapAndTools withToolBar id="container-etude-commerciale">
    <template #outils>
      <div class="pr-8 space-y-4">
        <ProCheckbox
          v-model="checked_dvf"
          :val="checked_dvf"
          id="ventes_proximite"
        >
          <div class="flex items-center justify-center">
            <div class="w-4 h-4 bg-promy-blue-250 rounded-md mr-2" />
            Ventes à Proximité
          </div>
        </ProCheckbox>
        <ProCheckbox
          v-model="checked_pc"
          :val="checked_pc"
          id="permis_construire"
        >
          <div class="flex items-center justify-center">
            <div class="w-4 h-4 bg-promy-orange-600 rounded-md mr-2" />
            Permis de construire
          </div>
        </ProCheckbox>
      </div>
    </template>
    <template
      #left-side
      v-if="ventes_proximite_list.length || permis_construire_list.length"
    >
      <div class="hidden lg:block w-screen" v-if="$mq != 'xl'">
        <accordion>
          <accordion-item>
            <template slot="accordion-trigger">
              <a
                @click="active_accordion_bottom = !active_accordion_bottom"
                class="w-full bg-white flex items-center justify-start px-6 sm:px-4 py-5 text-promy-primary font-bold font-main sm:text-sm"
              >
                <MarkerIcon
                  name="marker"
                  class="w-7 h-7 mr-4"
                  color="#10AAAF"
                />
                <div>
                  <p>
                    {{ address.information.properties.label }}
                  </p>
                  <span class="font-normal">{{
                    address.information.properties.context
                  }}</span>
                </div>
                <DownArrowIcon
                  v-if="active_accordion_bottom"
                  class="ml-auto w-3 h-3"
                  color="#81C2DE"
                />
                <UpArrowIcon v-else class="ml-auto w-3 h-3" color="#81C2DE" />
              </a>
            </template>
            <template slot="accordion-content">
              <Details
                :ventes_proximite="ventes_proximite_list"
                :permis_construire="permis_construire_list"
              />
            </template>
          </accordion-item>
        </accordion>
      </div>
      <Details
        class="lg:hidden"
        :ventes_proximite="ventes_proximite_list"
        :permis_construire="permis_construire_list"
      />
    </template>
    <template #footer-details-infos v-else>
      <EmptyStateOnMap
        title="Veille commerciale"
        name_icon="etude-commerciale"
        class="z-10"
      >
        <template #message-error>
          <span class="text-promy-gray-250"
            >Ces informations ne sont pas disponibles <br />
            pour cette adresse.</span
          >
        </template>
      </EmptyStateOnMap>
    </template>
    <template #layers>
      <MapType class="left-[34rem] lg:left-0" :layers="layers" />
    </template>
    <template #map>
      <Map
        id="map-etude-commerciale"
        class="size-map ml-auto"
        @addMakers="addMarkersTransactionsAndPermis"
        :ventes_proximite="ventes_proximite_list"
        :permis_construire="permis_construire_list"
      />
    </template>
  </LayoutMapAndTools>
</template>

<script>
import etudeCommerciale from '@/mixins/etudeCommerciale'
import LayoutMapAndTools from '../LayoutMapAndTools.vue'
import Details from './Details'
import Map from './Map.vue'
import { mapGetters } from 'vuex'

export default {
  mixins: [etudeCommerciale],
  components: {
    LayoutMapAndTools,
    Details,
    Map,
  },
  data() {
    return {
      active_accordion_bottom: false,
      layers: null,
    }
  },
  computed: {
    ...mapGetters({
      address: 'address/address',
      infos_commune: 'address/infos_commune',
    }),
  },

  created() {
    this.layers = [
      {
        label: 'Cadastre',
        image: 'Cadastre.svg',
        is_active: false,
        component: 'CadastreOfficiel',
        data: {
          code_insee: this.infos_commune.code_insee,
        },
      },
    ]
  },

  methods: {
    addMarkersTransactionsAndPermis() {
      this.addMarkersPC()
      this.addMarkersDVF()
    },
  },

  beforeRouteLeave(to, from, next) {
    this.removeMarkers([...this.markers_pc, ...this.markers_dvf])
    this.$destroy()
    next()
  },
}
</script>

<style lang="scss">
#container-etude-commerciale {
  #mapbox {
    @apply ml-auto h-full flex-1;

    @screen md {
      @apply w-full;
    }
  }
}

.slideBtn {
  transition-delay: 1s;
}
</style>
